<template>
    <!-- Start Call to action -->
    <section class="call-action overlay" data-stellar-background-ratio="0.5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="content">
                        <h2>Do you need Emergency Medical Care? Call @ 1234 56789</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porttitor dictum turpis nec gravida.</p>
                        <div class="button">
                            <router-link to="/contact" class="btn">Contact Now</router-link>
                            <router-link to="/contact" class="btn second">
                                Learn More <i class="fa fa-long-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--/ End Call to action -->
</template>

<script>
    export default {
        name: "SiteCall"
    }
</script>

<style scoped>

</style>
