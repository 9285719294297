<template>


    <router-view />  <!-- This is where the routed components will be displayed -->

</template>

<script>


export default {
  name: 'App',
}
</script>
