<template>
    <!-- Start clients -->
    <div class="clients overlay">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="owl-carousel clients-slider">
                        <div class="single-clients">
                            <img src="/img/client1.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client2.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client3.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client4.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client5.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client1.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client2.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client3.png" alt="#">
                        </div>
                        <div class="single-clients">
                            <img src="/img/client4.png" alt="#">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/Ens clients -->
</template>

<script>
    export default {
        name: "SiteClients"
    }
</script>

<style scoped>

</style>
